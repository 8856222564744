<template>
  <div id="container">
    <!-- dialogs -->
    <!-- 合同分项 -->
    <el-dialog
      width="1000px"
      :title="operateType === 'add' ? '新增合同分项' : '修改合同分项'"
      :visible.sync="contractItem_isShow"
      :close-on-click-modal="false"
    >
      <contractitem-form
        :contractItemForm="contractItemForm"
        ref="contractItemForm"
      ></contractitem-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="contractItem_isShow = false">取 消</el-button>
        <el-button type="primary" @click="submitForm('contractItemForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 合同外追加 -->
    <el-dialog
      width="800px"
      :title="
        additional_operateType === 'add' ? '新增合同外追加' : '修改合同外追加'
      "
      :visible.sync="additional_isShow"
      :close-on-click-modal="false"
    >
      <additional-form
        :additionalcontractForm="additionalcontractForm"
        ref="additionalcontractForm"
      ></additional-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="additional_isShow = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submitAdditionalForm('additionalcontractForm')"
          >保 存</el-button
        >
      </div>
    </el-dialog>

    <!-- 财务回款 -->
    <el-dialog
      width="800px"
      :title="
        fcollection_operateType === 'add' ? '新增财务回款' : '修改财务回款'
      "
      :visible.sync="fcollection_isShow"
      :close-on-click-modal="false"
    >
      <fcollectionbyid-form
        :collectionForm="fcollectionForm"
        ref="fcollectionForm"
      ></fcollectionbyid-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="fcollection_isShow = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submitfCollectionForm('fcollectionForm')"
          >保 存</el-button
        >
      </div>
    </el-dialog>

    <!-- 应收回款 -->
    <el-dialog
      width="800px"
      :title="
        collection_operateType === 'add' ? '新增应收回款' : '修改应收回款'
      "
      :visible.sync="collection_isShow"
      :close-on-click-modal="false"
    >
      <collectionbyid-form
        :collectionForm="collectionForm"
        ref="collectionForm"
      ></collectionbyid-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="collection_isShow = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submitCollectionForm('collectionForm')"
          >保 存</el-button
        >
      </div>
    </el-dialog>

    <!-- 物资调配 -->
    <el-dialog
      width="800px"
      :title="wuzidiaopeiTitle"
      :visible.sync="wuzidiaopei_isShow"
      :close-on-click-modal="false"
    >
      <wuzidiaopeibyid-form
        :wuzidiaopeiForm="wuzidiaopeiForm"
        ref="wuzidiaopeiForm"
      ></wuzidiaopeibyid-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="wuzidiaopei_isShow = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submitWuzidiaopeiForm('wuzidiaopeiForm')"
          >保 存</el-button
        >
      </div>
    </el-dialog>

    <!-- 库房出库 -->
    <el-dialog
      width="1000px"
      :title="
        kufangchuku_operateType === 'add' ? '新增库房出库' : '修改库房出库'
      "
      :visible.sync="kufangchuku_isShow"
      :close-on-click-modal="false"
    >
      <kufangchukubyid-form
        :kufangchukuForm="kufangchukuForm"
        :fileList="fileListChuku"
        ref="kufangchukuForm"
      ></kufangchukubyid-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeKufangchukuDialog">取 消</el-button>
        <el-button
          type="primary"
          @click="submitKufangchukuForm('kufangchukuForm')"
          >保 存</el-button
        >
      </div>
    </el-dialog>
    <!-- 库房出库显示照片 -->
    <el-dialog
      width="1000px"
      :visible.sync="chukuPhotos_isShow"
      :close-on-click-modal="false"
    >
      <div class="demo-image__lazy">
        <el-image
          v-for="url in chukuphotosurls"
          :key="url"
          :src="url"
        ></el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="chukuPhotos_isShow = false">关 闭</el-button>
      </span>
    </el-dialog>

    <!-- 物资回库调配 -->
    <!-- <el-dialog
      width="20%"
      :title="wuzihuikudiaopei_operateType === 'add' ? '新增物资回库调配' : '修改物资回库调配'"
      :visible.sync="wuzihuikudiaopei_isShow"
      :close-on-click-modal="false"
    >
      <wuzihuikudiaopeibyid-form :wuzihuikudiaopeiForm="wuzihuikudiaopeiForm" ref="wuzihuikudiaopeiForm"></wuzihuikudiaopeibyid-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="wuzihuikudiaopei_isShow = false">取 消</el-button>
        <el-button type="primary" @click="submitWuzihuikudiaopeiForm('wuzihuikudiaopeiForm')">保 存</el-button>
      </div>
    </el-dialog> -->

    <!-- 库房回库 -->
    <el-dialog
      width="1000px"
      :title="
        kufanghuiku_operateType === 'add' ? '新增库房回库' : '修改库房回库'
      "
      :visible.sync="kufanghuiku_isShow"
      :close-on-click-modal="false"
    >
      <kufanghuikubyid-form
        :kufanghuikuForm="kufanghuikuForm"
        :fileList="fileListHuiku"
        ref="kufanghuikuForm"
      ></kufanghuikubyid-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeKufanghuikuDialog">取 消</el-button>
        <el-button
          type="primary"
          @click="submitKufanghuikuForm('kufanghuikuForm')"
          >保 存</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      width="1000px"
      :visible.sync="huikuPhotos_isShow"
      :close-on-click-modal="false"
    >
      <div class="demo-image__lazy">
        <el-image
          v-for="url in huikuphotosurls"
          :key="url"
          :src="url"
        ></el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="huikuPhotos_isShow = false">关 闭</el-button>
      </span>
    </el-dialog>

    <!-- 合同结算 -->
    <el-dialog
      width="1000px"
      title="新增结算单"
      :visible.sync="jiesuan_isShow"
      :close-on-click-modal="false"
    >
      <jiesuan-form
        :jiesuanForm="jiesuanForm"
        :fileList="fileListJiesuan"
        ref="jiesuanForm"
      ></jiesuan-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeJiesuanDialog">取 消</el-button>
        <el-button type="primary" @click="submitJiesuanForm('jiesuanForm')"
          >保 存</el-button
        >
      </div>
    </el-dialog>
    <!-- 结算显示照片 -->
    <el-dialog
      width="1000px"
      :visible.sync="jiesuanPhotos_isShow"
      :close-on-click-modal="false"
    >
      <div class="demo-image__lazy">
        <el-image
          v-for="url in jiesuanphotosurls"
          :key="url"
          :src="url"
        ></el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="jiesuanPhotos_isShow = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- dialogs -->
    <h1 v-if="!ifShowContractDetail">本合同还未经过法务审核</h1>

    <div id="main" v-if="ifShowContractDetail">
      <el-row>
        <el-col :span="24">
          <div id="contractContainer">
            <div id="contractDetail">
              <div class="contractItemHeader">
                <div class="title1">合同明细</div>
                <div>
                  <el-button
                    type="primary"
                    :disabled="!canLegal_audit"
                    @click="legalAudit"
                    v-if="usertype.contract_legal_audit"
                    >法务审核</el-button
                  >
                  <el-button type="warning" @click="backList">返 回</el-button>
                </div>
              </div>
              <contractdetail-table
                :contractDetail="contractDetail"
                :userType="usertype"
              ></contractdetail-table>
            </div>

            <div id="contractItem">
              <div class="contractItemHeader">
                <div class="title1">合同分项报价</div>
                <div>
                  <el-button
                    type="primary"
                    @click="addContractItem"
                    v-if="usertype.contract_item_add"
                    >+ 新增</el-button
                  >
                  <el-button
                    type="primary"
                    @click="addContractItemRemark"
                    v-if="usertype.contract_item_add"
                    >备注修改分项报价</el-button
                  >
                </div>
              </div>
              <contractitem-table
                :userType="usertype"
                :tableData="contractItemTableData"
                @edit="editContractItem"
                @del="delContractItem"
              ></contractitem-table>
              <div v-if="contractitemremarktable_show">
                <p class="remark_title">修改分项报价备注</p>
                <el-table
                  :data="contractItemRemarkTable"
                  style="width: 100%"
                  border
                  :header-row-style="TableHeaderRowStyle"
                  :header-cell-style="TableHeaderCellStyle"
                  :row-style="TableRowStyle"
                  :cell-style="TableCellStyle"
                >
                  <el-table-column
                    prop="added_person_date"
                    label="时间"
                    min-width="10%"
                  >
                    <template slot-scope="scope">
                      <span>{{
                        scope.row.added_person_date
                          | FormatDate('yyyy-MM-dd hh:mm')
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="added_person"
                    label="添加人"
                    min-width="10%"
                  >
                  </el-table-column>
                  <el-table-column prop="remark" label="内容" min-width="80%">
                  </el-table-column>
                </el-table>
              </div>
              <div v-if="contractItemRemark_isShow">
                <el-form
                  ref="contractitemremarkform"
                  :model="contractItemRemarkForm"
                >
                  <el-form-item
                    label="分项报价修改内容"
                    prop="remark"
                    :rules="[
                      { required: true, message: '请输入备注', trigger: 'blur' }
                    ]"
                  >
                    <el-input
                      type="textarea"
                      :rows="3"
                      v-model="contractItemRemarkForm.remark"
                    ></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      type="primary"
                      @click="
                        contractItemRemarkFormSubmit('contractitemremarkform')
                      "
                      >保存</el-button
                    >
                    <el-button
                      type="primary"
                      @click="
                        ResetcontractItemRemarkForm('contractitemremarkform')
                      "
                      >取消</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <div id="additionalContract">
              <div class="contractItemHeader">
                <div class="title1">合同外记录</div>
                <div>
                  <el-button
                    type="primary"
                    @click="addAdditonalContract"
                    v-if="canAddAdditionContract"
                    >+ 新增</el-button
                  >
                  <el-button
                    type="primary"
                    @click="addAddiContractItemRemark"
                    v-if="canAddAdditionContract"
                    >备注修改合同外</el-button
                  >
                </div>
              </div>
              <additional-table
                :userType="usertype"
                :tableData="additionalContralTableData"
                @edit="editAdditionalContract"
                @del="delAdditionalContract"
              ></additional-table>
              <div v-if="addiContractitemremarktable_show">
                <p class="remark_title">合同外修改内容</p>
                <el-table
                  :data="addiContractItemRemarkTable"
                  style="width: 100%"
                  border
                  :header-row-style="TableHeaderRowStyle"
                  :header-cell-style="TableHeaderCellStyle"
                  :row-style="TableRowStyle"
                  :cell-style="TableCellStyle"
                >
                  <el-table-column
                    prop="added_person_date"
                    label="时间"
                    min-width="10%"
                  >
                    <template slot-scope="scope">
                      <span>{{
                        scope.row.added_person_date
                          | FormatDate('yyyy-MM-dd hh:mm')
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="added_person"
                    label="添加人"
                    min-width="10%"
                  >
                  </el-table-column>
                  <el-table-column prop="remark" label="内容" min-width="80%">
                  </el-table-column>
                </el-table>
              </div>
              <div v-if="addiContractItemRemark_isShow">
                <el-form
                  ref="addicontractitemremarkform"
                  :model="addiContractItemRemarkForm"
                >
                  <el-form-item
                    label="合同外修改内容"
                    prop="remark"
                    :rules="[
                      { required: true, message: '请输入备注', trigger: 'blur' }
                    ]"
                  >
                    <el-input
                      type="textarea"
                      :rows="3"
                      v-model="addiContractItemRemarkForm.remark"
                    ></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      type="primary"
                      @click="
                        addiContractItemRemarkFormSubmit(
                          'addicontractitemremarkform'
                        )
                      "
                      >保存</el-button
                    >
                    <el-button
                      type="primary"
                      @click="
                        ResetAddiContractItemRemarkForm(
                          'addicontractitemremarkform'
                        )
                      "
                      >取消</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div id="collectionContainer">
            <div id="collection">
              <div class="contractItemHeader">
                <div class="title2">应收回款记录</div>
                <div>
                  <el-button
                    type="primary"
                    @click="addCollection"
                    v-if="usertype.collection_add"
                    >+ 新增</el-button
                  >
                  <!-- <el-button type="primary" :disabled="!canFinancial_audit" @click="financialAudit" v-if="usertype.contract_financial_audit">
                财务审核
              </el-button> -->
                  <el-button
                    type="primary"
                    @click="addCollectionRemark"
                    v-if="usertype.collection_add"
                    >备注修改应收回款</el-button
                  >
                  <el-button type="warning" @click="backList">返 回</el-button>
                </div>
              </div>
              <collectionbyid-table
                :userType="usertype"
                :tableData="collectionTableData"
                @edit="editCollection"
                @del="delCollection"
                @addCaiwuhuikuan="addfCollection"
              ></collectionbyid-table>
              <div v-if="collectionremarktable_show">
                <p class="remark_title">应收回款修改内容</p>
                <el-table
                  :data="collectionRemarkTable"
                  style="width: 100%"
                  border
                  :header-row-style="TableHeaderRowStyle"
                  :header-cell-style="TableHeaderCellStyle"
                  :row-style="TableRowStyle"
                  :cell-style="TableCellStyle"
                >
                  <el-table-column
                    prop="added_person_date"
                    label="时间"
                    min-width="10%"
                  >
                    <template slot-scope="scope">
                      <span>{{
                        scope.row.added_person_date
                          | FormatDate('yyyy-MM-dd hh:mm')
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="added_person"
                    label="添加人"
                    min-width="10%"
                  >
                  </el-table-column>
                  <el-table-column prop="remark" label="内容" min-width="80%">
                  </el-table-column>
                </el-table>
              </div>
              <div v-if="collectionRemark_isShow">
                <el-form
                  ref="collectionremarkform"
                  :model="collectionRemarkForm"
                >
                  <el-form-item
                    label="应收回款修改内容"
                    prop="remark"
                    :rules="[
                      { required: true, message: '请输入备注', trigger: 'blur' }
                    ]"
                  >
                    <el-input
                      type="textarea"
                      :rows="3"
                      v-model="collectionRemarkForm.remark"
                    ></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      type="primary"
                      @click="
                        collectionRemarkFormSubmit('collectionremarkform')
                      "
                      >保存</el-button
                    >
                    <el-button
                      type="primary"
                      @click="ResetCollectionRemarkForm('collectionremarkform')"
                      >取消</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <div id="fcollection">
              <div class="contractItemHeader">
                <div class="title2">财务回款记录</div>
                <div>
                  <!-- <el-button type="primary" :disabled="!canAddfCollection" @click="addfCollection" v-if="usertype.financial_collection_add">+ 新增</el-button> -->
                  <el-button
                    type="primary"
                    :disabled="!canAddfCollection"
                    @click="addFcollectionRemark"
                    v-if="usertype.financial_collection_add"
                    >备注修改财务回款</el-button
                  >
                </div>
              </div>
              <fcollectionbyid-table
                :userType="usertype"
                :tableData="fcollectionTableData"
                @edit="editfCollection"
                @del="delfCollection"
              ></fcollectionbyid-table>
              <div v-if="fcollectionremarktable_show">
                <p class="remark_title">财务回款修改内容</p>
                <el-table
                  :data="fcollectionRemarkTable"
                  style="width: 100%"
                  border
                  :header-row-style="TableHeaderRowStyle"
                  :header-cell-style="TableHeaderCellStyle"
                  :row-style="TableRowStyle"
                  :cell-style="TableCellStyle"
                >
                  <el-table-column
                    prop="added_person_date"
                    label="时间"
                    min-width="10%"
                  >
                    <template slot-scope="scope">
                      <span>{{
                        scope.row.added_person_date
                          | FormatDate('yyyy-MM-dd hh:mm')
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="added_person"
                    label="添加人"
                    min-width="10%"
                  >
                  </el-table-column>
                  <el-table-column prop="remark" label="内容" min-width="80%">
                  </el-table-column>
                </el-table>
              </div>
              <div v-if="fcollectionRemark_isShow">
                <el-form
                  ref="fcollectionremarkform"
                  :model="fcollectionRemarkForm"
                >
                  <el-form-item
                    label="财务回款修改内容"
                    prop="remark"
                    :rules="[
                      { required: true, message: '请输入备注', trigger: 'blur' }
                    ]"
                  >
                    <el-input
                      type="textarea"
                      :rows="3"
                      v-model="fcollectionRemarkForm.remark"
                    ></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      type="primary"
                      @click="
                        fcollectionRemarkFormSubmit('fcollectionremarkform')
                      "
                      >保存</el-button
                    >
                    <el-button
                      type="primary"
                      @click="
                        ResetFcollectionRemarkForm('fcollectionremarkform')
                      "
                      >取消</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div id="chukuhuikuContainer">
            <div id="wuzidiaopei">
              <div class="contractItemHeader">
                <div>
                  <div class="title3" style="float:left">物资调配记录</div>
                  <div class="radio_order">
                    <el-radio-group
                      v-model="wuzidiaopei_order"
                      @change="setWuzidiaopei_order"
                    >
                      <el-radio :label="1">按调配时间排序</el-radio>
                      <el-radio :label="2">按材料规格排序</el-radio>
                    </el-radio-group>
                  </div>
                </div>
                <div>
                  <el-button
                    type="primary"
                    :disabled="!canAddWuzidiaopei"
                    @click="addWuzidiaopei"
                    v-if="usertype.wuzidiaopei_add"
                    >+ 新增</el-button
                  >

                  <el-button
                    type="primary"
                    :disabled="!canAddWuzidiaopei"
                    @click="addWuzidiaopeiRemark"
                    v-if="usertype.wuzidiaopei_add"
                    >备注修改物资调配</el-button
                  >
                  <el-button type="warning" @click="backList">返 回</el-button>
                </div>
              </div>
              <wuzidiaopeibyid-table
                :userType="usertype"
                :tableData="wuzidiaopeiTableData"
                @edit="editWuzidiaopei"
                @del="delWuzidiaopei"
              ></wuzidiaopeibyid-table>
              <div v-if="wuzidiaopeiremarktable_show">
                <p class="remark_title">物资调配修改内容</p>
                <el-table
                  :data="wuzidiaopeiRemarkTable"
                  style="width: 100%"
                  border
                  :header-row-style="TableHeaderRowStyle"
                  :header-cell-style="TableHeaderCellStyle"
                  :row-style="TableRowStyle"
                  :cell-style="TableCellStyle"
                >
                  <el-table-column
                    prop="added_person_date"
                    label="时间"
                    min-width="10%"
                  >
                    <template slot-scope="scope">
                      <span>{{
                        scope.row.added_person_date
                          | FormatDate('yyyy-MM-dd hh:mm')
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="added_person"
                    label="添加人"
                    min-width="10%"
                  >
                  </el-table-column>
                  <el-table-column prop="remark" label="内容" min-width="80%">
                  </el-table-column>
                </el-table>
              </div>
              <div v-if="wuzidiaopeiRemark_isShow">
                <el-form
                  ref="wuzidiaopeiremarkform"
                  :model="wuzidiaopeiRemarkForm"
                >
                  <el-form-item
                    label="物资调配修改内容"
                    prop="remark"
                    :rules="[
                      { required: true, message: '请输入备注', trigger: 'blur' }
                    ]"
                  >
                    <el-input
                      type="textarea"
                      :rows="3"
                      v-model="wuzidiaopeiRemarkForm.remark"
                    ></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      type="primary"
                      @click="
                        wuzidiaopeiRemarkFormSubmit('wuzidiaopeiremarkform')
                      "
                      >保存</el-button
                    >
                    <el-button
                      type="primary"
                      @click="
                        ResetWuzidiaopeiRemarkForm('wuzidiaopeiremarkform')
                      "
                      >取消</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <div id="kufangchuku">
              <div class="contractItemHeader">
                <div>
                  <div class="title3" style="float:left">库房出库记录</div>
                  <div class="radio_order">
                    <el-radio-group
                      v-model="kufangchuku_order"
                      @change="setKufangchuku_order"
                    >
                      <el-radio :label="1">按出库日期排序</el-radio>
                      <el-radio :label="2">按材料规格排序</el-radio>
                    </el-radio-group>
                  </div>
                </div>
                <div>
                  <el-button
                    type="primary"
                    :disabled="!canAddKufangchuku"
                    @click="addKufangchuku"
                    v-if="usertype.kufangchuku_add"
                    >+ 新增</el-button
                  >
                  <el-button
                    type="primary"
                    :disabled="!canAddKufangchuku"
                    @click="addKufangchukuRemark"
                    v-if="usertype.kufangchuku_add"
                    >备注修改库房出库</el-button
                  >
                </div>
              </div>
              <kufangchukubyid-table
                :userType="usertype"
                :tableData="kufangchukuTableData"
                @edit="editKufangchuku"
                @del="delKufangchuku"
                @wuziConfirm="confirmKufangchuku"
                @showPhotos="showPhotosinTableChuku"
              ></kufangchukubyid-table>
              <div v-if="kufangchukuremarktable_show">
                <p class="remark_title">库房出库修改内容</p>
                <el-table
                  :data="kufangchukuRemarkTable"
                  style="width: 100%"
                  border
                  :header-row-style="TableHeaderRowStyle"
                  :header-cell-style="TableHeaderCellStyle"
                  :row-style="TableRowStyle"
                  :cell-style="TableCellStyle"
                >
                  <el-table-column
                    prop="added_person_date"
                    label="时间"
                    min-width="10%"
                  >
                    <template slot-scope="scope">
                      <span>{{
                        scope.row.added_person_date
                          | FormatDate('yyyy-MM-dd hh:mm')
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="added_person"
                    label="添加人"
                    min-width="10%"
                  >
                  </el-table-column>
                  <el-table-column prop="remark" label="内容" min-width="80%">
                  </el-table-column>
                </el-table>
              </div>
              <div v-if="kufangchukuRemark_isShow">
                <el-form
                  ref="kufangchukuremarkform"
                  :model="kufangchukuRemarkForm"
                >
                  <el-form-item
                    label="库房出库修改内容"
                    prop="remark"
                    :rules="[
                      { required: true, message: '请输入备注', trigger: 'blur' }
                    ]"
                  >
                    <el-input
                      type="textarea"
                      :rows="3"
                      v-model="kufangchukuRemarkForm.remark"
                    ></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      type="primary"
                      @click="
                        kufangchukuRemarkFormSubmit('kufangchukuremarkform')
                      "
                      >保存</el-button
                    >
                    <el-button
                      type="primary"
                      @click="
                        ResetKufangchukuRemarkForm('kufangchukuremarkform')
                      "
                      >取消</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <!-- <div id="wuzihuikudiaopei">
          <div class="contractItemHeader">
            <div class="title3">物资回库调配</div>
            <el-button type="primary" size="mini" @click="addWuzihuikudiaopei" v-if="usertype.wuzihuikudiaopei_add">+ 新增</el-button>
          </div>
          <wuzihuikudiaopeibyid-table
            :userType="usertype"
            :tableData="wuzihuikudiaopeiTableData"
            @edit="editWuzihuikudiaopei"
            @del="delWuzihuikudiaopei"
          ></wuzihuikudiaopeibyid-table>
        </div> -->
            <div id="kufanghuiku">
              <div class="contractItemHeader">
                <div>
                  <div class="title3" style="float:left">库房回库记录</div>
                  <div class="radio_order">
                    <el-radio-group
                      v-model="kufanghuiku_order"
                      @change="setKufanghuiku_order"
                    >
                      <el-radio :label="1">按回库日期排序</el-radio>
                      <el-radio :label="2">按材料规格排序</el-radio>
                    </el-radio-group>
                  </div>
                </div>
                <div>
                  <el-button
                    type="primary"
                    :disabled="!canAddKufanghuiku"
                    @click="addKufanghuiku"
                    v-if="usertype.kufanghuiku_add"
                    >+ 新增</el-button
                  >
                  <el-button
                    type="primary"
                    @click="addKufanghuikuRemark"
                    v-if="usertype.kufanghuiku_add"
                    >备注修改库房回库</el-button
                  >
                </div>
              </div>
              <kufanghuikubyid-table
                :userType="usertype"
                :tableData="kufanghuikuTableData"
                @edit="editKufanghuiku"
                @del="delKufanghuiku"
                @showPhotos="showPhotosinTableHuiku"
                @wuziConfirm="confirmKufanghuiku"
              ></kufanghuikubyid-table>
              <div v-if="kufanghuikuremarktable_show">
                <p class="remark_title">库房回库修改内容</p>
                <el-table
                  :data="kufanghuikuRemarkTable"
                  style="width: 100%"
                  border
                  :header-row-style="TableHeaderRowStyle"
                  :header-cell-style="TableHeaderCellStyle"
                  :row-style="TableRowStyle"
                  :cell-style="TableCellStyle"
                >
                  <el-table-column
                    prop="added_person_date"
                    label="时间"
                    min-width="10%"
                  >
                    <template slot-scope="scope">
                      <span>{{
                        scope.row.added_person_date
                          | FormatDate('yyyy-MM-dd hh:mm')
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="added_person"
                    label="添加人"
                    min-width="10%"
                  >
                  </el-table-column>
                  <el-table-column prop="remark" label="内容" min-width="80%">
                  </el-table-column>
                </el-table>
              </div>
              <div v-if="kufanghuikuRemark_isShow">
                <el-form
                  ref="kufanghuikuremarkform"
                  :model="kufanghuikuRemarkForm"
                >
                  <el-form-item
                    label="库房回库修改内容"
                    prop="remark"
                    :rules="[
                      { required: true, message: '请输入备注', trigger: 'blur' }
                    ]"
                  >
                    <el-input
                      type="textarea"
                      :rows="3"
                      v-model="kufanghuikuRemarkForm.remark"
                    ></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      type="primary"
                      @click="
                        kufanghuikuRemarkFormSubmit('kufanghuikuremarkform')
                      "
                      >保存</el-button
                    >
                    <el-button
                      type="primary"
                      @click="
                        ResetKufanghuikuRemarkForm('kufanghuikuremarkform')
                      "
                      >取消</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <div id="jiesuan">
              <div class="contractItemHeader">
                <div class="title4">合同结算终审</div>
                <div>
                  <el-button
                    type="primary"
                    :disabled="!canAddJiesuan"
                    @click="addJiesuan"
                    v-if="usertype.jiesuan_add"
                    >+ 新增</el-button
                  >
                  <el-button
                    type="primary"
                    @click="deleteJiesuan"
                    v-if="usertype.id === 1"
                    >+ 删除</el-button
                  >
                  <el-button
                    type="primary"
                    :disabled="!canAddJiesuanRemark"
                    @click="addJiesuanRemark"
                    v-if="usertype.jiesuan_add"
                    >备注修改结算单</el-button
                  >
                  <el-button
                    type="primary"
                    :disabled="!canFinancial_confirm"
                    @click="financialConfirm"
                    v-if="usertype.contract_financial_confirm"
                  >
                    财务确认
                  </el-button>
                </div>
              </div>
              <jiesuan-table
                :userType="usertype"
                :jiesuan="jiesuanTableData"
                @showPhotos="showPhotosinTableJiesuan"
              ></jiesuan-table>
              <div v-if="jiesuanremarktable_show">
                <p class="remark_title">结算单修改内容</p>
                <el-table
                  :data="jiesuanRemarkTable"
                  style="width: 100%"
                  border
                  :header-row-style="TableHeaderRowStyle"
                  :header-cell-style="TableHeaderCellStyle"
                  :row-style="TableRowStyle"
                  :cell-style="TableCellStyle"
                >
                  <el-table-column
                    prop="added_person_date"
                    label="时间"
                    min-width="10%"
                  >
                    <template slot-scope="scope">
                      <span>{{
                        scope.row.added_person_date
                          | FormatDate('yyyy-MM-dd hh:mm')
                      }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="added_person"
                    label="添加人"
                    min-width="10%"
                  >
                  </el-table-column>
                  <el-table-column prop="remark" label="内容" min-width="80%">
                  </el-table-column>
                </el-table>
              </div>
              <div v-if="jiesuanRemark_isShow">
                <el-form ref="jiesuanremarkform" :model="jiesuanRemarkForm">
                  <el-form-item
                    label="结算单修改内容"
                    prop="remark"
                    :rules="[
                      { required: true, message: '请输入备注', trigger: 'blur' }
                    ]"
                  >
                    <el-input
                      type="textarea"
                      :rows="3"
                      v-model="jiesuanRemarkForm.remark"
                    ></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      type="primary"
                      @click="jiesuanRemarkFormSubmit('jiesuanremarkform')"
                      >保存</el-button
                    >
                    <el-button
                      type="primary"
                      @click="ResetJiesuanRemarkForm('jiesuanremarkform')"
                      >取消</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
              <div style="text-align:center">
                <el-button
                  type="primary"
                  :disabled="!canStatement"
                  @click="Statement"
                  v-if="usertype.contract_is_statement"
                >
                  合同终审结单
                </el-button>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import ContractdetailTable from './ContractDetailTable'
import ContractitemTable from './ContractItemTable'
import ContractitemForm from './ContractitemForm'
import AdditionalTable from './AdditionalTable'
import AdditionalForm from './AdditionalForm'
import CollectionbyidTable from './CollectionByIdTable'
import CollectionbyidForm from './CollectionByIdForm'
import FcollectionbyidTable from './FinancialByIdTable'
import FcollectionbyidForm from './FinancialByIdForm'
import WuzidiaopeibyidTable from './WuzidiaopeiByIdTable'
import WuzidiaopeibyidForm from './WuzidiaopeiByIdForm'
import KufangchukubyidTable from './KufangchukuByIdTable'
import KufangchukubyidForm from './KufangchukuByIdForm'
// import WuzihuikudiaopeibyidTable from './WuzihuikudiaopeiByIdTable'
// import WuzihuikudiaopeibyidForm from './WuzihuikudiaopeiByIdForm'
import KufanghuikubyidTable from './KufanghuikuByIdTable'
import KufanghuikubyidForm from './KufanghuikuByIdForm'
import JiesuanTable from './JiesuanTable'
import JiesuanForm from './JiesuanForm'
import utils from '../../assets/js/utils'

export default {
  data() {
    return {
      fileListChuku: [],
      fileListHuiku: [],
      fileListJiesuan: [],
      contractDetail: {},
      contractItemTableData: [],
      contractItemRemarkTable: [],
      additionalContralTableData: [],
      addiContractItemRemarkTable: [],
      collectionTableData: [],
      collectionRemarkTable: [],
      fcollectionTableData: [],
      fcollectionRemarkTable: [],
      wuzidiaopeiTableData: [],
      wuzidiaopeiRemarkTable: [],
      kufangchukuTableData: [],
      kufangchukuRemarkTable: [],
      // wuzihuikudiaopeiTableData: [],
      kufanghuikuTableData: [],
      kufanghuikuRemarkTable: [],
      jiesuanTableData: {},
      jiesuanRemarkTable: [],

      isShow: false,
      additional_isShow: false,
      contractItem_isShow: false,
      contractItemRemark_isShow: false,
      addiContractItemRemark_isShow: false,
      collection_isShow: false,
      collectionRemark_isShow: false,
      fcollection_isShow: false,
      fcollectionRemark_isShow: false,
      wuzidiaopei_isShow: false,
      wuzidiaopeiRemark_isShow: false,
      // wuzihuikudiaopei_isShow: false,
      kufangchuku_isShow: false,
      kufangchukuRemark_isShow: false,
      chukuPhotos_isShow: false,
      kufanghuiku_isShow: false,
      kufanghuikuRemark_isShow: false,
      huikuPhotos_isShow: false,
      jiesuan_isShow: false,
      jiesuanRemark_isShow: false,
      jiesuanPhotos_isShow: false,

      operateType: 'add',
      additional_operateType: 'add',
      contractItem_operateType: 'add',
      collection_operateType: 'add',
      fcollection_operateType: 'add',
      wuzidiaopei_operateType: 'add',
      kufangchuku_operateType: 'add',
      // wuzihuikudiaopei_operateType: 'add',
      kufanghuiku_operateType: 'add',

      contractItemForm: {},
      additionalcontractForm: {},
      contractItemRemarkForm: {
        contract: '',
        added_person: '',
        remark: ''
      },
      addiContractItemRemarkForm: {},
      collectionForm: {},
      collectionRemarkForm: {},
      fcollectionForm: {},
      fcollectionRemarkForm: {},
      wuzidiaopeiForm: {},
      wuzidiaopeiRemarkForm: {},
      kufangchukuForm: {},
      kufangchukuRemarkForm: {},
      // wuzihuikudiaopeiForm: {},
      kufanghuikuForm: {},
      kufanghuikuRemarkForm: {},
      jiesuanForm: {},
      jiesuanRemarkForm: {},

      currentContract: '',
      usertype: '',
      user_realname: '',
      currentChuku: '',
      currentHuiku: '',
      currentJiesuan: '',
      chukuphotosurls: [],
      huikuphotosurls: [],
      jiesuanphotosurls: [],
      wuzidiaopei_order: 1,
      kufangchuku_order: 1,
      kufanghuiku_order: 1
    }
  },
  computed: {
    wuzidiaopeiTitle: function() {
      if (this.wuzidiaopei_operateType === 'add') {
        let title1 =
          '新增物资调配 (' +
          this.contractDetail.contract_id +
          '--' +
          this.contractDetail.contract_name +
          ')'
        return title1
      } else {
        let title2 =
          '修改物资调配 (' +
          this.contractDetail.contract_id +
          '--' +
          this.contractDetail.contract_name +
          ')'
        return title2
      }
    },
    ifShowContractDetail: function() {
      //获取当前用户的用户类型
      if (!this.contractDetail.legal_audit) {
        console.log('usertype:------------------', this.usertype.id)
        if (
          this.usertype.id === 1 ||
          this.usertype.id === 3 ||
          this.usertype.id === 5
        ) {
          return true
        }
        return false
      }
      return true
    },
    canLegal_audit: function() {
      if (this.contractDetail.is_statement === true) return false
      if (this.contractItemTableData.length === 0) {
        return false
      } else {
        if (!this.contractDetail.legal_audit) return true
        else return false
      }
    },

    canFinancial_audit: function() {
      if (this.contractDetail.is_statement === true) return false
      if (this.collectionTableData.length === 0) {
        return false
      } else {
        if (!this.contractDetail.financial_audit) {
          return true
        } else {
          return false
        }
      }
    },
    canAddfCollection: function() {
      if (this.contractDetail.is_statement === true) return false
      if (this.collectionTableData.length === 0) {
        return false
      } else {
        return true
      }
    },
    canAddWuzidiaopei: function() {
      if (this.contractDetail.is_statement === true) return false
      if (this.fcollectionTableData.length === 0) {
        return false
      } else {
        return true
      }
    },
    canAddKufangchuku: function() {
      if (this.contractDetail.is_statement === true) return false
      if (this.wuzidiaopeiTableData.length === 0) {
        return false
      } else {
        return true
      }
    },
    canAddKufanghuiku: function() {
      if (this.contractDetail.is_statement === true) return false
      if (this.kufangchukuTableData.length === 0) {
        return false
      } else {
        return true
      }
    },
    canAddJiesuan: function() {
      if (this.contractDetail.is_statement === true) return false
      if (this.kufanghuikuTableData.length === 0) {
        return false
      } else {
        if (this.contractDetail.is_statement === false) {
          return true
        } else return false
      }
    },
    canAddJiesuanRemark: function() {
      if (this.contractDetail.is_statement === true) {
        return false
      } else {
        return true
      }
    },
    contractitemremarktable_show: function() {
      let temp = this.contractItemRemarkTable.length === 0
      console.log('contractitemremarktable_show', temp)
      if (this.usertype.contract_item_amount && !temp) return true
      else return false
    },
    addiContractitemremarktable_show: function() {
      let temp = this.addiContractItemRemarkTable.length === 0
      console.log('addiContractitemremarktable_show', temp)
      if (this.usertype.contract_item_amount && !temp) return true
      else return false
    },
    canAddAdditionContract: function() {
      if (this.contractDetail.is_statement === true) return false
      if (
        this.usertype.additional_contract_add &&
        this.contractDetail.legal_audit
      ) {
        return true
      } else return false
    },
    canFinancial_confirm: function() {
      if (this.contractDetail.is_statement === true) return false
      let isnull = JSON.stringify(this.jiesuanTableData) === '{}' //判断结算单是否为空，为空返回true
      if (isnull) {
        return false
      } else {
        if (!this.contractDetail.financial_confirm) {
          return true
        } else {
          return false
        }
      }
    },
    collectionremarktable_show: function() {
      let temp = this.collectionRemarkTable.length === 0
      console.log('collectionremarktable_show', temp)
      if (this.usertype.contract_item_amount && !temp) return true
      else return false
    },
    fcollectionremarktable_show: function() {
      let temp = this.fcollectionRemarkTable.length === 0
      console.log('fcollectionremarktable_show', temp)
      if (this.usertype.contract_item_amount && !temp) return true
      else return false
    },
    wuzidiaopeiremarktable_show: function() {
      let temp = this.wuzidiaopeiRemarkTable.length === 0
      console.log('wuzidiaopeiremarktable_show', temp)
      if (!temp) return true
      else return false
    },
    kufangchukuremarktable_show: function() {
      let temp = this.kufangchukuRemarkTable.length === 0
      console.log('kufangchukuremarktable_show', temp)
      if (!temp) return true
      else return false
    },
    kufanghuikuremarktable_show: function() {
      let temp = this.kufanghuikuRemarkTable.length === 0
      console.log('kufanghuikuremarktable_show', temp)
      if (!temp) return true
      else return false
    },
    jiesuanremarktable_show: function() {
      let temp = this.jiesuanRemarkTable.length === 0
      console.log('jiesuanremarktable_show', temp)
      if (this.usertype.contract_item_amount && !temp) return true
      else return false
    },
    canStatement: function() {
      if (this.contractDetail.is_statement === true) return false
      if (this.contractDetail.financial_confirm === true) return true
      return false
    }
  },
  components: {
    ContractdetailTable,
    ContractitemTable,
    ContractitemForm,
    AdditionalTable,
    AdditionalForm,
    CollectionbyidTable,
    CollectionbyidForm,
    FcollectionbyidTable,
    FcollectionbyidForm,
    WuzidiaopeibyidTable,
    WuzidiaopeibyidForm,
    KufangchukubyidTable,
    KufangchukubyidForm,
    // WuzihuikudiaopeibyidTable,
    // WuzihuikudiaopeibyidForm,
    KufanghuikubyidTable,
    KufanghuikubyidForm,
    JiesuanTable,
    JiesuanForm
  },
  methods: {
    //获取合同详情
    getContractDetail() {
      this.currentContract = this.$route.query.id
      console.log('contract_id:', this.currentContract)
      //获取当前用户信息：姓名，用户类型
      let user = utils.getUserCookie()
      this.usertype = user.user_type
      this.user_realname = user.name
      console.log('user:', user)
      const api = '/getContractDetail/'
      this.$axios
        .get(api, {
          params: {
            id: this.currentContract
          }
        })
        .then(res => {
          console.log('res.data', res.data)
          this.contractDetail = res.data
          if (this.usertype.contract_detail_amount === false) {
            this.contractDetail.contract_amount = '******'
          }
          if (this.usertype.contract_detail_real_amount === false) {
            this.contractDetail.real_amount = '******'
          }
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    //获取合同分项信息
    getContractItem() {
      console.log('contract_id:', this.currentContract)
      const api = '/getContractItem/'
      this.$axios
        .get(api, {
          params: {
            id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            console.log('合同分项res.data', res.data.data)
            this.contractItemTableData = res.data.data.map(item => {
              item.added = item.added_person + '\n' + item.added_person_time
              if (this.usertype.contract_item_amount === false) {
                item.item_amount = '******'
              }
              if (this.usertype.contract_item_remark === false) {
                item.remark = '******'
              }
              return item
            })
          } else console.log('合同分项信息为空！')
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    //新增合同分项信息
    addContractItem() {
      this.contractItemForm = {}
      this.contractItem_operateType = 'add'
      this.contractItem_isShow = true
    },
    //编辑合同分项信息
    editContractItem(row) {
      console.log('row........', row)
      this.contractItem_operateType = 'edit'
      this.contractItem_isShow = true
      row.item_amount = parseInt(row.item_amount)
      this.contractItemForm = row
      console.log('The new row:', this.contractItemForm)
    },

    //删除合同分项信息
    delContractItem(row) {
      this.$confirm('此操作将永久删除此合同分项信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let id = row.id
          this.$axios
            .get('/deleteContractItem/', {
              params: {
                id
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getContractItem()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    // //关闭合同分项对话框
    // contractItem_dlgClose(formName) {
    //   let form = this.$refs[formName]
    //   form.resetForm()
    //   this.contractItem_isShow = false
    // },

    submitForm(formName) {
      let form = this.$refs[formName]
      form.submitForm()
      if (form.isValidationOk) {
        if (this.contractItem_operateType === 'edit') {
          //编辑当前合同分项信息
          console.log('The new row 2:', this.contractItemForm)
          this.contractItemForm.contract = this.currentContract
          this.$axios
            .put('/updateContractItem/', this.contractItemForm)
            .then(res => {
              console.log('response:', res.data)
              if (res.data.code === 1000) {
                this.$message({
                  type: 'success',
                  message: '更新合同成功!',
                  duration: 2000
                })
                this.contractItem_isShow = false
                this.getContractItem()
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000
                })
              }
            })
        } else {
          //增加合同分项
          console.log('add contractItem:', this.contractItemForm)
          this.contractItemForm.contract = this.currentContract
          this.contractItemForm.added_person = this.user_realname
          this.contractItemForm.added_person_time = utils.getDateTime()
          this.$axios
            .post('/addContractItem/', this.contractItemForm)
            .then(res => {
              console.log('response:', res.data)
              if (res.data.code === 1000) {
                this.$message({
                  type: 'success',
                  message: '增加合同成功!',
                  duration: 2000
                })
                this.contractItem_isShow = false
                this.getContractItem()
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000
                })
              }
            })
        }
      } else {
        console.log('校验不通过！')
      }
    },

    //获取修改合同分项备注
    getContractItemRemark() {
      console.log('contract_id:', this.currentContract)
      const api = '/getContractItemRemark/'
      this.$axios
        .get(api, {
          params: {
            id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            console.log('修改合同分项备注res.data', res.data.data)
            this.contractItemRemarkTable = res.data.data
          } else console.log('修改合同分项备注为空！')
        })
        .catch(function(error) {
          console.log(error)
        })
    },

    //添加修改合同分项备注
    addContractItemRemark() {
      this.contractItemRemark_isShow = true
      this.contractItemRemarkForm = {}
    },

    //增加修改合同分项备注表单复位
    ResetcontractItemRemarkForm(formName) {
      this.$refs[formName].resetFields()
      this.contractItemRemark_isShow = false
    },
    //增加修改合同分项备注表单提交
    contractItemRemarkFormSubmit(formName) {
      console.log(this.$refs[formName])
      this.$refs[formName].validate(valid => {
        if (valid) {
          console.log('22222222222222')
          //增加修改合同分项备注
          this.contractItemRemarkForm.contract = this.currentContract
          this.contractItemRemarkForm.added_person = this.user_realname

          this.$axios
            .post('/addContractItemRemark/', this.contractItemRemarkForm)
            .then(res => {
              console.log('response:', res.data)
              if (res.data.code === 1000) {
                this.$message({
                  type: 'success',
                  message: '增加修改合同分项备注成功!',
                  duration: 2000
                })
                this.contractItemRemark_isShow = false
                this.getContractItemRemark()
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000
                })
              }
            })
        } else {
          console.log('校验不通过！')
          return false
        }
      })
    },
    //获取合同外追加信息
    getAdditionalContract() {
      console.log('contract_id:', this.currentContract)
      const api = '/getAdditionalContractByID/'
      this.$axios
        .get(api, {
          params: {
            id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            console.log('res.data', res.data.data)
            this.additionalContralTableData = res.data.data.map(item => {
              item.added = item.added_person + '\n' + item.added_person_time
              if (this.usertype.additional_contract_amount === false) {
                item.additional_contract_amount = '******'
              }
              return item
            })
          } else console.log('合同分项信息为空！')
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    //增加合同外追加
    addAdditonalContract() {
      this.additionalcontractForm = {}
      this.additional_operateType = 'add'
      this.additional_isShow = true
    },
    //编辑合同外追加
    editAdditionalContract(row) {
      console.log('row........', row)
      this.operateType = 'edit'
      this.additional_isShow = true
      row.additional_contract_amount = parseInt(row.additional_contract_amount)
      this.additionalcontractForm = row
      console.log('The new row:', this.additionalcontractForm)
    },

    //删除合同外追加
    delAdditionalContract(row) {
      this.$confirm('此操作将永久删除此合同外追加, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let id = row.id
          this.$axios
            .get('/deleteAdditionalContract/', {
              params: {
                id
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getAdditionalContract()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    //提交合同外追加表单
    submitAdditionalForm(formName) {
      let form = this.$refs[formName]
      form.submitForm()
      if (form.isValidationOk) {
        if (this.operateType === 'edit') {
          //编辑当前合同外追加
          console.log('The new row edit:', this.additionalcontractForm)
          this.$axios
            .put('/updateAdditionalContract/', this.additionalcontractForm)
            .then(res => {
              console.log('response:', res.data)
              if (res.data.code === 1000) {
                this.$message({
                  type: 'success',
                  message: '更新合同外追加成功!',
                  duration: 2000
                })
                this.additional_isShow = false
                this.getAdditionalContract()
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000
                })
              }
            })
        } else {
          //增加合同外追加
          this.additionalcontractForm.contract = this.currentContract
          this.additionalcontractForm.added_person = this.user_realname
          this.additionalcontractForm.added_person_time = utils.getDateTime()
          console.log(
            'add additionalcontractForm:',
            this.additionalcontractForm
          )
          this.$axios
            .post('/addAdditionalContract/', this.additionalcontractForm)
            .then(res => {
              console.log('response:', res.data)
              if (res.data.code === 1000) {
                this.$message({
                  type: 'success',
                  message: '增加合同外追加成功!',
                  duration: 2000
                })
                this.additional_isShow = false
                this.getAdditionalContract()
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000
                })
              }
            })
        }
      } else {
        console.log('校验不通过！')
      }
    },
    /*
    合同外修改备注
    */
    //获取修改合同外备注
    getAddiContractRemark() {
      console.log('contract_id:', this.currentContract)
      const api = '/getAddiContractItemRemark/'
      this.$axios
        .get(api, {
          params: {
            id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            console.log('修改合同外备注res.data', res.data.data)
            this.addiContractItemRemarkTable = res.data.data
          } else console.log('修改合同外备注为空！')
        })
        .catch(function(error) {
          console.log(error)
        })
    },

    //添加修改合同外备注
    addAddiContractItemRemark() {
      this.addiContractItemRemark_isShow = true
      this.addiContractItemRemarkForm = {}
    },

    //增加修改合同外备注表单复位
    ResetAddiContractItemRemarkForm(formName) {
      this.$refs[formName].resetFields()
      this.addiContractItemRemark_isShow = false
    },
    //增加修改合同外备注表单提交
    addiContractItemRemarkFormSubmit(formName) {
      console.log(this.$refs[formName])
      this.$refs[formName].validate(valid => {
        if (valid) {
          console.log('22222222222222')
          //增加修改合同外备注
          this.addiContractItemRemarkForm.contract = this.currentContract
          this.addiContractItemRemarkForm.added_person = this.user_realname

          this.$axios
            .post(
              '/addAddiContractItemRemark/',
              this.addiContractItemRemarkForm
            )
            .then(res => {
              console.log('response:', res.data)
              if (res.data.code === 1000) {
                this.$message({
                  type: 'success',
                  message: '增加修改合同外备注成功!',
                  duration: 2000
                })
                this.addiContractItemRemark_isShow = false
                this.getAddiContractRemark()
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000
                })
              }
            })
        } else {
          console.log('校验不通过！')
          return false
        }
      })
    },

    /*
    法务审核
    */
    legalAudit() {
      this.$confirm('确认通过法律审核吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let operateTime = utils.getDateTime()
          console.log(operateTime)
          let params = {
            id: this.currentContract,
            user: this.user_realname,
            operatetime: operateTime
          }

          this.$axios.put('/legalAudit/', params).then(res => {
            console.log(res.data)
            this.$message({
              type: 'success',
              message: '法务审核成功!'
            })
            this.getContractDetail()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消法务审核'
          })
        })
    },

    /*
    应收回款
    */
    //获取应收回款信息
    getCollection() {
      console.log('contract_id:', this.currentContract)
      const api = '/getCollectionsByID/'
      this.$axios
        .get(api, {
          params: {
            id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            console.log('res.data', res.data.data)
            this.collectionTableData = res.data.data.map(item => {
              item.added =
                item.added_person +
                '\n' +
                utils.formatDateTime(item.added_person_time, 'yyyy-MM-dd hh:mm')
              if (this.usertype.collection_amount === false) {
                item.collection_amount = '******'
              }
              return item
            })
          } else console.log('应收回款信息为空！')
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    //增加应收回款信息
    addCollection() {
      this.collectionForm = {}
      this.collection_operateType = 'add'
      this.collection_isShow = true
    },
    //编辑应收回款信息
    editCollection(row) {
      console.log('row........', row)
      this.collection_operateType = 'edit'
      this.collection_isShow = true
      row.collection_amount = parseInt(row.collection_amount)
      this.collectionForm = row
      console.log('The new row:', this.collectionForm)
    },

    //删除应收回款信息
    delCollection(row) {
      this.$confirm('此操作将永久删除此应收回款信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let id = row.id
          this.$axios
            .get('/deleteCollection/', {
              params: {
                id
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getCollection()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    //提交应收回款表单
    submitCollectionForm(formName) {
      let form = this.$refs[formName]
      form.submitForm()
      if (form.isValidationOk) {
        if (this.collection_operateType === 'edit') {
          //编辑当前合同外追加
          console.log('The new row edit:', this.collectionForm)
          this.$axios
            .put('/updateCollection/', this.collectionForm)
            .then(res => {
              console.log('response:', res.data)
              if (res.data.code === 1000) {
                this.$message({
                  type: 'success',
                  message: '更新应收回款信息成功!',
                  duration: 2000
                })
                this.collection_isShow = false
                this.getCollection()
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000
                })
              }
            })
        } else {
          //增加应收回款
          this.collectionForm.contract = this.currentContract
          this.collectionForm.added_person = this.user_realname
          // this.collectionForm.added_person_time = utils.getDateTime()
          console.log('add collectionForm:', this.collectionForm)
          this.$axios.post('/addCollection/', this.collectionForm).then(res => {
            console.log('response:', res.data)
            if (res.data.code === 1000) {
              this.$message({
                type: 'success',
                message: '增加应收回款信息成功!',
                duration: 2000
              })
              this.collection_isShow = false
              this.getCollection()
            } else {
              this.$message({
                type: 'warning',
                message: res.data.msg,
                duration: 2000
              })
            }
          })
        }
      } else {
        console.log('校验不通过！')
      }
    },

    //获取修改应收回款备注
    getCollectionRemark() {
      console.log('contract_id:', this.currentContract)
      const api = '/getCollectionRemark/'
      this.$axios
        .get(api, {
          params: {
            id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            console.log('修改应收回款备注res.data', res.data.data)
            this.collectionRemarkTable = res.data.data
          } else console.log('修改应收回款备注为空！')
        })
        .catch(function(error) {
          console.log(error)
        })
    },

    //添加修改合同回款备注
    addCollectionRemark() {
      this.collectionRemark_isShow = true
      this.collectionRemarkForm = {}
    },

    //增加修改应收回款备注表单复位
    ResetCollectionRemarkForm(formName) {
      this.$refs[formName].resetFields()
      this.collectionRemark_isShow = false
    },
    //增加修改应收回款备注表单提交
    collectionRemarkFormSubmit(formName) {
      console.log(this.$refs[formName])
      this.$refs[formName].validate(valid => {
        if (valid) {
          //增加修改应收回款备注
          this.collectionRemarkForm.contract = this.currentContract
          this.collectionRemarkForm.added_person = this.user_realname

          this.$axios
            .post('/addCollectionRemark/', this.collectionRemarkForm)
            .then(res => {
              console.log('response:', res.data)
              if (res.data.code === 1000) {
                this.$message({
                  type: 'success',
                  message: '增加修改应收回款备注成功!',
                  duration: 2000
                })
                this.collectionRemark_isShow = false
                this.getCollectionRemark()
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000
                })
              }
            })
        } else {
          console.log('校验不通过！')
          return false
        }
      })
    },

    /*
    财务审核
    */
    // financialAudit() {
    //   this.$confirm('确认通过财务审核吗?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   })
    //     .then(() => {
    //       let operateTime = utils.getDateTime()
    //       console.log(operateTime)
    //       let params = {
    //         id: this.currentContract,
    //         user: this.user_realname,
    //         operatetime: operateTime
    //       }

    //       this.$axios.put('/financialAudit/', params).then(res => {
    //         console.log(res.data)
    //         this.$message({
    //           type: 'success',
    //           message: '财务审核成功!'
    //         })
    //         this.getContractDetail()
    //       })
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: 'info',
    //         message: '已取消财务审核'
    //       })
    //     })
    // },

    // caiwuShenhe(row) {
    //   this.$confirm('确认通过财务审核吗?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   })
    //     .then(() => {
    //       let operateTime = utils.getDateTime()
    //       console.log(operateTime)
    //       let params = {
    //         collection_id: row.id,
    //         user: this.user_realname,
    //         operatetime: operateTime
    //       }

    //       this.$axios.put('/caiwuShenhe/', params).then(res => {
    //         console.log(res.data)
    //         this.$message({
    //           type: 'success',
    //           message: '财务审核成功!'
    //         })
    //         this.getCollection()
    //       })
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: 'info',
    //         message: '已取消财务审核'
    //       })
    //     })
    // },

    /*
    财务回款
    */
    //获取财务回款信息
    getfCollection() {
      console.log('contract_id:', this.currentContract)
      const api = '/getFinancialCollectionsByID/'
      this.$axios
        .get(api, {
          params: {
            id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            console.log('res.data', res.data.data)
            this.fcollectionTableData = res.data.data.map(item => {
              item.added =
                item.added_person +
                '\n' +
                utils.formatDateTime(item.added_person_time, 'yyyy-MM-dd hh:mm')
              if (this.usertype.financial_collection_amount === false) {
                item.collection_amount = '******'
              }
              return item
            })
          } else console.log('财务回款信息为空！')
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    //增加财务回款信息
    addfCollection(row) {
      this.fcollectionForm = {}
      this.fcollection_operateType = 'add'
      this.fcollection_isShow = true
      this.fcollectionForm.collection = row.id
    },
    //编辑财务回款信息
    editfCollection(row) {
      console.log('row........', row)
      this.fcollection_operateType = 'edit'
      this.fcollection_isShow = true
      row.collection_amount = parseInt(row.collection_amount)
      this.fcollectionForm = row
      console.log('The new row:', this.fcollectionForm)
    },

    //删除财务回款信息
    delfCollection(row) {
      this.$confirm('此操作将永久删除此财务回款信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let id = row.id
          this.$axios
            .get('/deleteFinancialCollection/', {
              params: {
                id
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getfCollection()
              this.getCollection()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    //提交财务回款表单
    submitfCollectionForm(formName) {
      let form = this.$refs[formName]
      form.submitForm()
      if (form.isValidationOk) {
        if (this.fcollection_operateType === 'edit') {
          //编辑当前财务回款
          console.log('The new row edit:', this.fcollectionForm)
          this.$axios
            .put('/updateFinancialCollection/', this.fcollectionForm)
            .then(res => {
              console.log('response:', res.data)
              if (res.data.code === 1000) {
                this.$message({
                  type: 'success',
                  message: '更新财务回款信息成功!',
                  duration: 2000
                })
                this.fcollection_isShow = false
                this.getfCollection()
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000
                })
              }
            })
        } else {
          //增加财务回款
          this.fcollectionForm.contract = this.currentContract
          this.fcollectionForm.added_person = this.user_realname
          // this.fcollectionForm.added_person_time = utils.getDateTime()
          console.log('add fcollectionForm:', this.fcollectionForm)
          this.$axios
            .post('/addFinancialCollection/', this.fcollectionForm)
            .then(res => {
              console.log('response:', res.data)
              if (res.data.code === 1000) {
                this.$message({
                  type: 'success',
                  message: '增加财务回款信息成功!',
                  duration: 2000
                })
                this.fcollection_isShow = false
                this.getCollection()
                this.getfCollection()
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000
                })
              }
            })
        }
      } else {
        console.log('校验不通过！')
      }
    },

    //获取修改财务回款备注
    getFcollectionRemark() {
      console.log('contract_id:', this.currentContract)
      const api = '/getFcollectionRemark/'
      this.$axios
        .get(api, {
          params: {
            id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            console.log('修改财务回款备注res.data', res.data.data)
            this.fcollectionRemarkTable = res.data.data
          } else console.log('修改财务回款备注为空！')
        })
        .catch(function(error) {
          console.log(error)
        })
    },

    //添加修改财务回款备注
    addFcollectionRemark() {
      this.fcollectionRemark_isShow = true
      this.fcollectionRemarkForm = {}
    },

    //增加修改财务回款备注表单复位
    ResetFcollectionRemarkForm(formName) {
      this.$refs[formName].resetFields()
      this.fcollectionRemark_isShow = false
    },
    //增加修改财务回款备注表单提交
    fcollectionRemarkFormSubmit(formName) {
      console.log(this.$refs[formName])
      this.$refs[formName].validate(valid => {
        if (valid) {
          //增加修改财务回款备注
          this.fcollectionRemarkForm.contract = this.currentContract
          this.fcollectionRemarkForm.added_person = this.user_realname

          this.$axios
            .post('/addFcollectionRemark/', this.fcollectionRemarkForm)
            .then(res => {
              console.log('response:', res.data)
              if (res.data.code === 1000) {
                this.$message({
                  type: 'success',
                  message: '增加修改财务回款备注成功!',
                  duration: 2000
                })
                this.fcollectionRemark_isShow = false
                this.getFcollectionRemark()
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000
                })
              }
            })
        } else {
          console.log('校验不通过！')
          return false
        }
      })
    },

    /*
    物资调配记录
    */
    //获取物资调配信息
    getWuzidiaopei() {
      console.log('contract_id:', this.currentContract)
      const api = '/getWuzidiaopeisByID/'
      this.$axios
        .get(api, {
          params: {
            id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            console.log('res.data', res.data.data)
            this.wuzidiaopeiTableData = res.data.data.map(item => {
              item.added = item.added_person + '\n' + item.added_person_time
              return item
            })
            //统计小计和总计
            let newArray = [] //存储原数据及小计和合计
            if (this.wuzidiaopei_order === 1) {
              //按调配时间、录入时间排序
              newArray = newArray.concat(this.wuzidiaopeiTableData)
              console.log('newArray----------------:', newArray)
            }
            //1. 排序
            this.wuzidiaopeiTableData.sort(function(a, b) {
              //如果“材料名称”相同，按照“材料规格”排序，如果材料规格相同，按照添加时间倒序
              if (a.cailiao === b.cailiao) {
                if (a.cailiaoguige === b.cailiaoguige) {
                  let a_date = utils.stringToDate(a.added_person_time)
                  let b_date = utils.stringToDate(b.added_person_time)
                  return a_date < b_date ? 1 : -1
                } else {
                  return a.cailiaoguige < b.cailiaoguige ? -1 : 1
                }
              } else {
                return a.cailiao < b.cailiao ? -1 : 1
              }
            })
            if (this.wuzidiaopei_order === 2) {
              //按材料、材料规格排序
              newArray = newArray.concat(this.wuzidiaopeiTableData)
              console.log('newArray----------------:', newArray)
            }
            console.log('this.wuzidiaopeiTableData', this.wuzidiaopeiTableData)
            //2. 计算小计和总计
            let array = this.wuzidiaopeiTableData
            // let newArray = [array[0]]

            let prior = array[0]
            let xiaoji = parseInt(prior.amount)
            let zongji = xiaoji

            console.log('xiaoji', xiaoji)
            for (let index = 1; index < array.length; index++) {
              console.log('the index:', index)
              let curr = array[index]
              //总计
              zongji = parseInt(zongji) + parseInt(curr.amount)
              if (
                curr.cailiao_name === prior.cailiao_name &&
                curr.cailiaoguige_name === prior.cailiaoguige_name
              ) {
                // newArray.push(curr)
                xiaoji += parseInt(curr.amount)
                prior = curr
                //如果是最后一条记录,生成小计
                if (index === array.length - 1) {
                  let xiaojiTitle =
                    curr.cailiao_name +
                    '-' +
                    curr.cailiaoguige_name +
                    ' ' +
                    '小计'
                  let temp = {
                    cailiao_name: xiaojiTitle,
                    amount: xiaoji,
                    type: '2'
                  } //type=2表示小计行，type=3表示合计行
                  newArray.push(temp)
                }
              } else {
                //生成前一个材料规格的小计
                let xiaojiTitle =
                  prior.cailiao_name +
                  '-' +
                  prior.cailiaoguige_name +
                  ' ' +
                  '小计'
                let temp = {
                  cailiao_name: xiaojiTitle,
                  amount: xiaoji,
                  type: '2'
                }
                newArray.push(temp)
                //插入本条记录
                // newArray.push(curr)
                //初始化新的小计
                xiaoji = parseInt(curr.amount)
                prior = curr
                //如果是最后一条记录,生成小计
                if (index === array.length - 1) {
                  let xiaojiTitle =
                    curr.cailiao_name +
                    '-' +
                    curr.cailiaoguige_name +
                    ' ' +
                    '小计'
                  let temp = {
                    cailiao_name: xiaojiTitle,
                    amount: xiaoji,
                    type: '2'
                  }
                  console.log('the last record', temp)
                  newArray.push(temp)
                }
              }
            }
            // console.log(zongji)

            let hejiTitle = '合计'
            let heji = { cailiao_name: hejiTitle, amount: zongji, type: '3' }
            newArray.push(heji)
            console.log('newarray', newArray)
            this.wuzidiaopeiTableData = newArray
          } else {
            console.log('物资调配信息为空！')
            this.wuzidiaopeiTableData = []
          }
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    //增加物资调配信息
    addWuzidiaopei() {
      this.wuzidiaopeiForm = {}
      this.wuzidiaopei_operateType = 'add'
      this.wuzidiaopei_isShow = true
      // this.wuzidiaopeiForm.collection = row.id
    },
    //编辑物资调配信息
    editWuzidiaopei(row) {
      console.log('row........', row)
      this.wuzidiaopei_operateType = 'edit'
      this.wuzidiaopei_isShow = true
      row.amount = parseInt(row.amount)
      this.wuzidiaopeiForm = row
      console.log('The new row:', this.wuzidiaopeiForm)
    },

    //删除物资调配信息
    delWuzidiaopei(row) {
      this.$confirm('此操作将永久删除此物资调配信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let id = row.id
          this.$axios
            .get('/deleteWuzidiaopei/', {
              params: {
                id
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getWuzidiaopei()
              this.getCollection()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    //提交物资调配表单
    submitWuzidiaopeiForm(formName) {
      let form = this.$refs[formName]
      form.submitForm()
      if (form.isValidationOk) {
        if (this.wuzidiaopei_operateType === 'edit') {
          //编辑当前物资调配
          console.log('The new row edit:', this.wuzidiaopeiForm)
          this.$axios
            .put('/updateWuzidiaopei/', this.wuzidiaopeiForm)
            .then(res => {
              console.log('response:', res.data)
              if (res.data.code === 1000) {
                this.$message({
                  type: 'success',
                  message: '更新物资调配信息成功!',
                  duration: 2000
                })
                this.wuzidiaopei_isShow = false
                this.getWuzidiaopei()
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000
                })
              }
            })
        } else {
          //增加物资调配
          this.wuzidiaopeiForm.contract = this.currentContract
          this.wuzidiaopeiForm.added_person = this.user_realname
          this.wuzidiaopeiForm.added_person_time = utils.getDateTime()
          console.log('add wuzidiaopeiForm:', this.wuzidiaopeiForm)
          this.$axios
            .post('/addWuzidiaopei/', this.wuzidiaopeiForm)
            .then(res => {
              console.log('response:', res.data)
              if (res.data.code === 1000) {
                this.$message({
                  type: 'success',
                  message: '增加物资调配信息成功!',
                  duration: 2000
                })
                this.wuzidiaopei_isShow = false
                this.getWuzidiaopei()
                this.getCollection()
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000
                })
              }
            })
        }
      } else {
        console.log('校验不通过！')
      }
    },

    //获取修改物资调配备注
    getWuzidiaopeiRemark() {
      console.log('contract_id:', this.currentContract)
      const api = '/getWuzidiaopeiRemark/'
      this.$axios
        .get(api, {
          params: {
            id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            console.log('修改物资调配备注res.data', res.data.data)
            this.wuzidiaopeiRemarkTable = res.data.data
          } else console.log('修改物资调配备注为空！')
        })
        .catch(function(error) {
          console.log(error)
        })
    },

    //添加修改物资调配备注
    addWuzidiaopeiRemark() {
      this.wuzidiaopeiRemark_isShow = true
      this.wuzidiaopeiRemarkForm = {}
    },

    //增加修改物资调配备注表单复位
    ResetWuzidiaopeiRemarkForm(formName) {
      this.$refs[formName].resetFields()
      this.wuzidiaopeiRemark_isShow = false
    },
    //增加修改物资调配备注表单提交
    wuzidiaopeiRemarkFormSubmit(formName) {
      console.log(this.$refs[formName])
      this.$refs[formName].validate(valid => {
        if (valid) {
          //增加修改物资调配备注
          this.wuzidiaopeiRemarkForm.contract = this.currentContract
          this.wuzidiaopeiRemarkForm.added_person = this.user_realname

          this.$axios
            .post('/addWuzidiaopeiRemark/', this.wuzidiaopeiRemarkForm)
            .then(res => {
              console.log('response:', res.data)
              if (res.data.code === 1000) {
                this.$message({
                  type: 'success',
                  message: '增加修改物资调配备注成功!',
                  duration: 2000
                })
                this.wuzidiaopeiRemark_isShow = false
                this.getWuzidiaopeiRemark()
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000
                })
              }
            })
        } else {
          console.log('校验不通过！')
          return false
        }
      })
    },

    /*
    库房出库记录
    */
    //获取库房出库信息
    getKufangchuku() {
      console.log('contract_id:', this.currentContract)
      const api = '/getKufangchukusByID/'
      this.$axios
        .get(api, {
          params: {
            id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            console.log('res.data', res.data.data)
            this.kufangchukuTableData = res.data.data.map(item => {
              item.added = item.added_person + '\n' + item.added_person_time
              return item
            })
            // alert('1000')
            console.log('this.kufangchukuTableData', this.kufangchukuTableData)
            //统计小计和总计
            let newArray = [] //存储原数据及小计和合计
            if (this.kufangchuku_order === 1) {
              //按出库日期、录入时间排序
              newArray = newArray.concat(this.kufangchukuTableData)
              console.log('newArray----------------:', newArray)
            }

            //1. 排序
            this.kufangchukuTableData.sort(function(a, b) {
              //如果“材料名称”相同，按照“材料规格”排序，如果材料规格相同，按照添加时间倒序
              if (a.cailiao === b.cailiao) {
                if (a.cailiaoguige === b.cailiaoguige) {
                  let a_date = utils.stringToDate(a.added_person_time)
                  let b_date = utils.stringToDate(b.added_person_time)
                  return a_date < b_date ? 1 : -1
                } else {
                  return a.cailiaoguige < b.cailiaoguige ? -1 : 1
                }
              } else {
                return a.cailiao < b.cailiao ? -1 : 1
              }
            })
            if (this.kufangchuku_order === 2) {
              //按材料、材料规格排序
              newArray = newArray.concat(this.kufangchukuTableData)
              console.log('newArray----------------:', newArray)
            }
            console.log('this.kufangchukuTableData', this.kufangchukuTableData)
            //2. 计算小计和总计
            let array = this.kufangchukuTableData
            // let newArray = [array[0]]
            // let newArray = [] //存储小计和合计
            let prior = array[0]
            let xiaoji = parseInt(prior.amount)
            let zongji = xiaoji

            console.log('xiaoji', xiaoji)
            for (let index = 1; index < array.length; index++) {
              console.log('the index:', index)
              let curr = array[index]
              //总计
              zongji = parseInt(zongji) + parseInt(curr.amount)
              if (
                curr.cailiao_name === prior.cailiao_name &&
                curr.cailiaoguige_name === prior.cailiaoguige_name
              ) {
                // newArray.push(curr)
                xiaoji += parseInt(curr.amount)
                prior = curr
                //如果是最后一条记录,生成小计
                if (index === array.length - 1) {
                  let xiaojiTitle =
                    curr.cailiao_name +
                    '-' +
                    curr.cailiaoguige_name +
                    ' ' +
                    '小计'
                  let temp = {
                    cailiao_name: xiaojiTitle,
                    amount: xiaoji,
                    type: '2'
                  } //type=2表示小计行，type=3表示合计行
                  newArray.push(temp)
                }
              } else {
                //生成前一个材料规格的小计
                let xiaojiTitle =
                  prior.cailiao_name +
                  '-' +
                  prior.cailiaoguige_name +
                  ' ' +
                  '小计'
                let temp = {
                  cailiao_name: xiaojiTitle,
                  amount: xiaoji,
                  type: '2'
                }
                newArray.push(temp)
                //插入本条记录
                // newArray.push(curr)
                //初始化新的小计
                xiaoji = parseInt(curr.amount)
                prior = curr
                //如果是最后一条记录,生成小计
                if (index === array.length - 1) {
                  let xiaojiTitle =
                    curr.cailiao_name +
                    '-' +
                    curr.cailiaoguige_name +
                    ' ' +
                    '小计'
                  let temp = {
                    cailiao_name: xiaojiTitle,
                    amount: xiaoji,
                    type: '2'
                  }
                  console.log('the last record', temp)
                  newArray.push(temp)
                }
              }
            }
            // console.log(zongji)

            let hejiTitle = '合计'
            let heji = { cailiao_name: hejiTitle, amount: zongji, type: '3' }
            newArray.push(heji)
            console.log('newarray', newArray)
            this.kufangchukuTableData = newArray
          } else {
            console.log('库房出库信息为空！')
            this.kufangchukuTableData = []
          }
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    //增加库房出库信息
    addKufangchuku() {
      this.kufangchukuForm = {}
      this.fileListChuku = []
      this.kufangchuku_operateType = 'add'
      this.kufangchuku_isShow = true
    },
    //编辑库房出库信息
    editKufangchuku(row) {
      console.log('row........', row)
      this.kufangchuku_operateType = 'edit'
      this.fileListChuku = []
      this.showPhotosinFormChuku(row)
      row.amount = parseInt(row.amount)
      this.kufangchukuForm = row
      this.kufangchuku_isShow = true
      console.log('The new row:', this.kufangchukuForm)
    },

    //删除库房出库信息
    delKufangchuku(row) {
      this.$confirm('此操作将永久删除此库房出库信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let id = row.id
          this.$axios
            .get('/deleteKufangchuku/', {
              params: {
                id
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getKufangchuku()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    //库房出库物资审核
    confirmKufangchuku(row) {
      this.$confirm('确认审核通过?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let id = row.id
          this.$axios
            .get('/confirmKufangchuku/', {
              params: {
                id
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '审核操作成功!'
              })
              this.getKufangchuku()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },

    //提交库房出库表单
    submitKufangchukuForm(formName) {
      let form = this.$refs[formName]
      form.submitForm()
      if (form.isValidationOk) {
        if (this.kufangchuku_operateType === 'edit') {
          //编辑当前库房出库
          console.log('The new row edit:', this.kufangchukuForm)
          this.$axios
            .put('/updateKufangchuku/', this.kufangchukuForm)
            .then(res => {
              console.log('response:', res.data)
              if (res.data.code === 1000) {
                this.$message({
                  type: 'success',
                  message: '更新库房出库信息成功!',
                  duration: 2000
                })
                this.kufangchuku_isShow = false
                this.getKufangchuku()
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000
                })
              }
            })
        } else {
          //增加库房出库
          this.kufangchukuForm.contract = this.currentContract
          this.kufangchukuForm.added_person = this.user_realname
          this.kufangchukuForm.added_person_time = utils.getDateTime()
          console.log('add kufangchukuForm:', this.kufangchukuForm)
          this.$axios
            .post('/addKufangchuku/', this.kufangchukuForm)
            .then(res => {
              console.log('response:', res.data)
              if (res.data.code === 1000) {
                this.$message({
                  type: 'success',
                  message: '增加库房出库信息成功!',
                  duration: 2000
                })
                this.kufangchuku_isShow = false
                this.getKufangchuku()
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000
                })
              }
            })
        }
      } else {
        console.log('校验不通过！')
      }
    },
    closeKufangchukuDialog() {
      this.kufangchuku_isShow = false
      this.fileListChuku = []
    },
    //在table中显示图片
    showPhotosinTableChuku(row) {
      this.chukuphotosurls = []
      let photos = row.photos
      let strs = photos.split(',') //字符分割
      for (let i = 0; i < strs.length; i++) {
        let filename = strs[i].replace('media/images/', '')
        const baseUrl = 'https://jsjz-bak.obs.cn-north-4.myhuaweicloud.com/'
        let url = baseUrl + filename //服务器

        // let url = strs[i] //本地

        this.chukuphotosurls.push(url)
      }
      console.log(this.chukuphotosurls)
      this.chukuPhotos_isShow = true
    },
    //在form中显示之前上传过的图片
    showPhotosinFormChuku(row) {
      let photos = row.photos
      console.log('photos', photos)
      let strs = photos.split(',') //字符分割
      for (let i = 0; i < strs.length; i++) {
        let photo = {}
        let str = strs[i].split('/')
        let name = str[str.length - 1]
        photo['name'] = name
        photo['url'] = strs[i]
        this.fileListChuku.push(photo)
      }
      console.log('fileListChuku', this.fileListChuku)
    },

    //获取修改库房出库备注
    getKufangchukuRemark() {
      console.log('contract_id:', this.currentContract)
      const api = '/getKufangchukuRemark/'
      this.$axios
        .get(api, {
          params: {
            id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            console.log('修改库房出库备注res.data', res.data.data)
            this.kufangchukuRemarkTable = res.data.data
          } else console.log('修改库房出库备注为空！')
        })
        .catch(function(error) {
          console.log(error)
        })
    },

    //添加修改库房出库备注
    addKufangchukuRemark() {
      this.kufangchukuRemark_isShow = true
      this.kufangchukuRemarkForm = {}
    },

    //增加修改库房出库备注表单复位
    ResetKufangchukuRemarkForm(formName) {
      this.$refs[formName].resetFields()
      this.kufangchukuRemark_isShow = false
    },
    //增加修改库房出库备注表单提交
    kufangchukuRemarkFormSubmit(formName) {
      console.log(this.$refs[formName])
      this.$refs[formName].validate(valid => {
        if (valid) {
          //增加修改库房出库备注
          this.kufangchukuRemarkForm.contract = this.currentContract
          this.kufangchukuRemarkForm.added_person = this.user_realname

          this.$axios
            .post('/addKufangchukuRemark/', this.kufangchukuRemarkForm)
            .then(res => {
              console.log('response:', res.data)
              if (res.data.code === 1000) {
                this.$message({
                  type: 'success',
                  message: '增加修改库房出库备注成功!',
                  duration: 2000
                })
                this.kufangchukuRemark_isShow = false
                this.getKufangchukuRemark()
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000
                })
              }
            })
        } else {
          console.log('校验不通过！')
          return false
        }
      })
    },

    /*
    物资回库调配
    */
    //获取物资回库调配信息
    // getWuzihuikudiaopei() {
    //   console.log('contract_id:', this.currentContract)
    //   const api = '/getWuzihuikudiaopeisByID/'
    //   this.$axios
    //     .get(api, {
    //       params: {
    //         id: this.currentContract
    //       }
    //     })
    //     .then(res => {
    //       if (res.data.code === 1000) {
    //         console.log('res.data', res.data.data)
    //         this.wuzihuikudiaopeiTableData = res.data.data.map(item => {
    //           item.added = item.added_person + '\n' + item.added_person_time
    //           return item
    //         })
    //         console.log('this.wuzihuikudiaopeiTableData', this.wuzihuikudiaopeiTableData)
    //       } else console.log('物资回库调配信息为空！')
    //     })
    //     .catch(function(error) {
    //       console.log(error)
    //     })
    // },
    // //增加物资回库调配信息
    // addWuzihuikudiaopei() {
    //   this.wuzihuikudiaopeiForm = {}
    //   this.wuzihuikudiaopei_operateType = 'add'
    //   this.wuzihuikudiaopei_isShow = true
    // },
    // //编辑物资回库调配信息
    // editWuzihuikudiaopei(row) {
    //   console.log('row........', row)
    //   this.wuzihuikudiaopei_operateType = 'edit'
    //   this.wuzihuikudiaopei_isShow = true
    //   row.amount = parseInt(row.amount)
    //   this.wuzihuikudiaopeiForm = row
    //   console.log('The new row:', this.wuzihuikudiaopeiForm)
    // },

    // //删除物资回库调配信息
    // delWuzihuikudiaopei(row) {
    //   this.$confirm('此操作将永久删除此物资回库调配信息, 是否继续?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   })
    //     .then(() => {
    //       let id = row.id
    //       this.$axios
    //         .get('/deleteWuzihuikudiaopei/', {
    //           params: {
    //             id
    //           }
    //         })
    //         .then(res => {
    //           console.log(res.data)
    //           this.$message({
    //             type: 'success',
    //             message: '删除成功!'
    //           })
    //           this.getWuzihuikudiaopei()
    //         })
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: 'info',
    //         message: '已取消删除'
    //       })
    //     })
    // },
    // //提交物资回库回库调配表单
    // submitWuzihuikudiaopeiForm(formName) {
    //   let form = this.$refs[formName]
    //   form.submitForm()
    //   if (form.isValidationOk) {
    //     if (this.wuzihuikudiaopei_operateType === 'edit') {
    //       //编辑当前物资回库调配
    //       console.log('The new row edit:', this.wuzihuikudiaopeiForm)
    //       this.$axios.put('/updateWuzihuikudiaopei/', this.wuzihuikudiaopeiForm).then(res => {
    //         console.log('response:', res.data)
    //         if (res.data.code === 1000) {
    //           this.$message({
    //             type: 'success',
    //             message: '更新物资回库调配信息成功!',
    //             duration: 2000
    //           })
    //           this.wuzihuikudiaopei_isShow = false
    //           this.getWuzihuikudiaopei()
    //         } else {
    //           this.$message({
    //             type: 'warning',
    //             message: res.data.msg,
    //             duration: 2000
    //           })
    //         }
    //       })
    //     } else {
    //       //增加物资回库调配
    //       this.wuzihuikudiaopeiForm.contract = this.currentContract
    //       this.wuzihuikudiaopeiForm.added_person = this.user_realname
    //       this.wuzihuikudiaopeiForm.added_person_time = utils.getDateTime()
    //       console.log('add wuzihuikudiaopeiForm:', this.wuzihuikudiaopeiForm)
    //       this.$axios.post('/addWuzihuikudiaopei/', this.wuzihuikudiaopeiForm).then(res => {
    //         console.log('response:', res.data)
    //         if (res.data.code === 1000) {
    //           this.$message({
    //             type: 'success',
    //             message: '增加物资回库调配信息成功!',
    //             duration: 2000
    //           })
    //           this.wuzihuikudiaopei_isShow = false
    //           this.getWuzihuikudiaopei()
    //         } else {
    //           this.$message({
    //             type: 'warning',
    //             message: res.data.msg,
    //             duration: 2000
    //           })
    //         }
    //       })
    //     }
    //   } else {
    //     console.log('校验不通过！')
    //   }
    // },

    /*
    库房回库记录
    */
    //获取库房回库信息
    getKufanghuiku() {
      console.log('contract_id:', this.currentContract)
      const api = '/getKufanghuikusByID/'
      this.$axios
        .get(api, {
          params: {
            id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            console.log('res.data', res.data.data)
            this.kufanghuikuTableData = res.data.data.map(item => {
              item.added = item.added_person + '\n' + item.added_person_time
              return item
            })
            console.log('this.kufanghuikuTableData', this.kufanghuikuTableData)
            //统计小计和总计
            let newArray = [] //存储原数据及小计和合计
            if (this.kufanghuiku_order === 1) {
              //按回库日期、录入时间排序
              newArray = newArray.concat(this.kufanghuikuTableData)
              console.log('newArray----------------:', newArray)
            }

            //1. 排序
            this.kufanghuikuTableData.sort(function(a, b) {
              //如果“材料名称”相同，按照“材料规格”排序，如果材料规格相同，按照添加时间倒序
              if (a.cailiao === b.cailiao) {
                if (a.cailiaoguige === b.cailiaoguige) {
                  let a_date = utils.stringToDate(a.added_person_time)
                  let b_date = utils.stringToDate(b.added_person_time)
                  return a_date < b_date ? 1 : -1
                } else {
                  return a.cailiaoguige < b.cailiaoguige ? -1 : 1
                }
              } else {
                return a.cailiao < b.cailiao ? -1 : 1
              }
            })
            if (this.kufanghuiku_order === 2) {
              //按材料、材料规格排序
              newArray = newArray.concat(this.kufanghuikuTableData)
              console.log('newArray----------------:', newArray)
            }
            console.log('this.kufanghuikuTableData', this.kufanghuikuTableData)
            //2. 计算小计和总计
            let array = this.kufanghuikuTableData
            // let newArray = [array[0]]
            // let newArray = [] //存储小计和合计
            let prior = array[0]
            let xiaoji = parseInt(prior.amount)
            let zongji = xiaoji

            console.log('xiaoji', xiaoji)
            for (let index = 1; index < array.length; index++) {
              console.log('the index:', index)
              let curr = array[index]
              //总计
              zongji = parseInt(zongji) + parseInt(curr.amount)
              if (
                curr.cailiao_name === prior.cailiao_name &&
                curr.cailiaoguige_name === prior.cailiaoguige_name
              ) {
                // newArray.push(curr)
                xiaoji += parseInt(curr.amount)
                prior = curr
                //如果是最后一条记录,生成小计
                if (index === array.length - 1) {
                  let xiaojiTitle =
                    curr.cailiao_name +
                    '-' +
                    curr.cailiaoguige_name +
                    ' ' +
                    '小计'
                  let temp = {
                    cailiao_name: xiaojiTitle,
                    amount: xiaoji,
                    type: '2'
                  } //type=2表示小计行，type=3表示合计行
                  newArray.push(temp)
                }
              } else {
                //生成前一个材料规格的小计
                let xiaojiTitle =
                  prior.cailiao_name +
                  '-' +
                  prior.cailiaoguige_name +
                  ' ' +
                  '小计'
                let temp = {
                  cailiao_name: xiaojiTitle,
                  amount: xiaoji,
                  type: '2'
                }
                newArray.push(temp)
                //插入本条记录
                // newArray.push(curr)
                //初始化新的小计
                xiaoji = parseInt(curr.amount)
                prior = curr
                //如果是最后一条记录,生成小计
                if (index === array.length - 1) {
                  let xiaojiTitle =
                    curr.cailiao_name +
                    '-' +
                    curr.cailiaoguige_name +
                    ' ' +
                    '小计'
                  let temp = {
                    cailiao_name: xiaojiTitle,
                    amount: xiaoji,
                    type: '2'
                  }
                  console.log('the last record', temp)
                  newArray.push(temp)
                }
              }
            }
            // console.log(zongji)

            let hejiTitle = '合计'
            let heji = { cailiao_name: hejiTitle, amount: zongji, type: '3' }
            newArray.push(heji)
            console.log('newarray', newArray)
            this.kufanghuikuTableData = newArray
          } else {
            this.kufanghuikuTableData = []
            console.log('库房回库信息为空！')
          }
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    //库房回库物资审核
    confirmKufanghuiku(row) {
      this.$confirm('确认审核通过?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let id = row.id
          this.$axios
            .get('/confirmKufanghuiku/', {
              params: {
                id
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '审核操作成功!'
              })
              this.getKufanghuiku()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    //增加库房回库信息
    addKufanghuiku() {
      this.kufanghuikuForm = {}
      this.fileListHuiku = []
      this.kufanghuiku_operateType = 'add'
      this.kufanghuiku_isShow = true
    },
    //编辑库房回库信息
    editKufanghuiku(row) {
      console.log('row........', row)
      this.kufanghuiku_operateType = 'edit'
      this.fileListHuiku = []
      this.showPhotosinFormHuiku(row)
      row.amount = parseInt(row.amount)
      this.kufanghuikuForm = row
      this.kufanghuiku_isShow = true
      console.log('The new row:', this.kufanghuikuForm)
    },

    //删除库房回库信息
    delKufanghuiku(row) {
      this.$confirm('此操作将永久删除此库房回库信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let id = row.id
          this.$axios
            .get('/deleteKufanghuiku/', {
              params: {
                id
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getKufanghuiku()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    //提交库房回库表单
    submitKufanghuikuForm(formName) {
      let form = this.$refs[formName]
      form.submitForm()
      if (form.isValidationOk) {
        if (this.kufanghuiku_operateType === 'edit') {
          //编辑当前库房回库
          console.log('The new row edit:', this.kufanghuikuForm)
          this.$axios
            .put('/updateKufanghuiku/', this.kufanghuikuForm)
            .then(res => {
              console.log('response:', res.data)
              if (res.data.code === 1000) {
                this.$message({
                  type: 'success',
                  message: '更新库房回库信息成功!',
                  duration: 2000
                })
                this.kufanghuiku_isShow = false
                this.getKufanghuiku()
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000
                })
              }
            })
        } else {
          //增加库房回库
          this.kufanghuikuForm.contract = this.currentContract
          this.kufanghuikuForm.added_person = this.user_realname
          this.kufanghuikuForm.added_person_time = utils.getDateTime()
          console.log('add kufanghuikuForm:', this.kufanghuikuForm)
          this.$axios
            .post('/addKufanghuiku/', this.kufanghuikuForm)
            .then(res => {
              console.log('response:', res.data)
              if (res.data.code === 1000) {
                this.$message({
                  type: 'success',
                  message: '增加库房回库信息成功!',
                  duration: 2000
                })
                this.kufanghuiku_isShow = false
                this.getKufanghuiku()
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000
                })
              }
            })
        }
      } else {
        console.log('校验不通过！')
      }
    },
    closeKufanghuikuDialog() {
      this.kufanghuiku_isShow = false
      this.fileListHuiku = []
    },
    //在table中显示图片
    showPhotosinTableHuiku(row) {
      this.huikuphotosurls = []
      let photos = row.photos
      console.log('huikuphotos:', photos)
      let strs = photos.split(',') //字符分割
      for (let i = 0; i < strs.length; i++) {
        let filename = strs[i].replace('media/images/', '')
        const baseUrl = 'https://jsjz-bak.obs.cn-north-4.myhuaweicloud.com/'
        let url = baseUrl + filename //服务器

        // let url = strs[i] //本地
        this.huikuphotosurls.push(url)
      }
      console.log(this.huikuphotosurls)
      this.huikuPhotos_isShow = true
    },
    //在form中显示之前上传过的图片
    showPhotosinFormHuiku(row) {
      let photos = row.photos
      console.log('photos', photos)
      let strs = photos.split(',') //字符分割
      for (let i = 0; i < strs.length; i++) {
        let photo = {}
        let str = strs[i].split('/')
        let name = str[str.length - 1]
        photo['name'] = name
        photo['url'] = strs[i]
        this.fileListHuiku.push(photo)
      }
      console.log('fileListHuiku', this.fileListHuiku)
    },

    //获取修改库房回库备注
    getKufanghuikuRemark() {
      console.log('contract_id:', this.currentContract)
      const api = '/getKufanghuikuRemark/'
      this.$axios
        .get(api, {
          params: {
            id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            console.log('修改库房回库备注res.data', res.data.data)
            this.kufanghuikuRemarkTable = res.data.data
          } else console.log('修改库房回库备注为空！')
        })
        .catch(function(error) {
          console.log(error)
        })
    },

    //添加修改库房回库备注
    addKufanghuikuRemark() {
      this.kufanghuikuRemark_isShow = true
      this.kufanghuikuRemarkForm = {}
    },

    //增加修改库房回库备注表单复位
    ResetKufanghuikuRemarkForm(formName) {
      this.$refs[formName].resetFields()
      this.kufanghuikuRemark_isShow = false
    },
    //增加修改库房回库备注表单提交
    kufanghuikuRemarkFormSubmit(formName) {
      console.log(this.$refs[formName])
      this.$refs[formName].validate(valid => {
        if (valid) {
          //增加修改库房回库备注
          this.kufanghuikuRemarkForm.contract = this.currentContract
          this.kufanghuikuRemarkForm.added_person = this.user_realname

          this.$axios
            .post('/addKufanghuikuRemark/', this.kufanghuikuRemarkForm)
            .then(res => {
              console.log('response:', res.data)
              if (res.data.code === 1000) {
                this.$message({
                  type: 'success',
                  message: '增加修改库房回库备注成功!',
                  duration: 2000
                })
                this.kufanghuikuRemark_isShow = false
                this.getKufanghuikuRemark()
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000
                })
              }
            })
        } else {
          console.log('校验不通过！')
          return false
        }
      })
    },

    /*
    合同终审结算
    */
    //获取合同终审信息
    getJiesuan() {
      console.log('contract_id:', this.currentContract)
      const api = '/getJiesuanByID/'
      this.$axios
        .get(api, {
          params: {
            id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            console.log('结算res.data', res.data.data)
            this.jiesuanTableData = res.data.data
            if (this.usertype.jiesuan_amount === false) {
              this.jiesuanTableData.amount = '******'
            }

            console.log('this.jiesuanTableData', this.jiesuanTableData)
          } else {
            this.jiesuanTableData = {}
            console.log('结算信息为空！')
          }
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    //增加合同结算信息
    addJiesuan() {
      console.log('dddddddddddfff')
      this.jiesuanForm = {}
      this.fileListJiesuan = []
      this.jiesuan_isShow = true
    },

    //提交合同结算表单
    submitJiesuanForm(formName) {
      let form = this.$refs[formName]
      form.submitForm()
      if (form.isValidationOk) {
        //增加合同结算
        this.jiesuanForm.contract = this.currentContract
        this.jiesuanForm.added_person = this.user_realname
        this.jiesuanForm.added_person_time = utils.getDateTime()
        console.log('add jiesuanForm:', this.jiesuanForm)
        this.$axios.post('/addJiesuan/', this.jiesuanForm).then(res => {
          console.log('response:', res.data)
          if (res.data.code === 1000) {
            this.$message({
              type: 'success',
              message: '增加合同结算信息成功!',
              duration: 2000
            })
            this.jiesuan_isShow = false
            this.getJiesuan()
          } else {
            this.$message({
              type: 'warning',
              message: res.data.msg,
              duration: 2000
            })
          }
        })
      } else {
        console.log('校验不通过！')
      }
    },
    closeJiesuanDialog() {
      this.jiesuan_isShow = false
      this.fileListJiesuan = []
    },
    //在table中显示图片
    showPhotosinTableJiesuan() {
      this.jiesuanphotosurls = []
      let photos = this.jiesuanTableData.photos
      let strs = photos.split(',') //字符分割
      for (let i = 0; i < strs.length; i++) {
        let filename = strs[i].replace('media/images/', '')
        const baseUrl = 'https://jsjz-bak.obs.cn-north-4.myhuaweicloud.com/'
        let url = baseUrl + filename //服务器

        // let url = strs[i] //本地
        this.jiesuanphotosurls.push(url)
      }
      console.log(this.jiesuanphotosurls)
      this.jiesuanPhotos_isShow = true
    },

    //获取修改结算单备注
    getJiesuanRemark() {
      console.log('contract_id:', this.currentContract)
      const api = '/getJiesuanRemark/'
      this.$axios
        .get(api, {
          params: {
            id: this.currentContract
          }
        })
        .then(res => {
          if (res.data.code === 1000) {
            console.log('修改结算备注res.data', res.data.data)
            this.jiesuanRemarkTable = res.data.data
          } else console.log('修改结算备注为空！')
        })
        .catch(function(error) {
          console.log(error)
        })
    },

    //添加修改结算备注
    addJiesuanRemark() {
      this.jiesuanRemark_isShow = true
      this.jiesuanRemarkForm = {}
    },

    //增加修改结算备注表单复位
    ResetJiesuanRemarkForm(formName) {
      this.$refs[formName].resetFields()
      this.jiesuanRemark_isShow = false
    },
    //增加修改结算备注表单提交
    jiesuanRemarkFormSubmit(formName) {
      console.log(this.$refs[formName])
      this.$refs[formName].validate(valid => {
        if (valid) {
          //增加修改结算备注
          this.jiesuanRemarkForm.contract = this.currentContract
          this.jiesuanRemarkForm.added_person = this.user_realname

          this.$axios
            .post('/addJiesuanRemark/', this.jiesuanRemarkForm)
            .then(res => {
              console.log('response:', res.data)
              if (res.data.code === 1000) {
                this.$message({
                  type: 'success',
                  message: '增加修改结算备注成功!',
                  duration: 2000
                })
                this.jiesuanRemark_isShow = false
                this.getJiesuanRemark()
              } else {
                this.$message({
                  type: 'warning',
                  message: res.data.msg,
                  duration: 2000
                })
              }
            })
        } else {
          console.log('校验不通过！')
          return false
        }
      })
    },
    //删除结算单
    deleteJiesuan() {
      this.$confirm('此操作将永久删除此结算单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$axios
            .get('/deleteJiesuan/', {
              params: {
                id: this.currentContract
              }
            })
            .then(res => {
              console.log(res.data)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
              this.getJiesuan()
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },

    /*
      财务确认
    */
    financialConfirm() {
      this.$confirm('财务确认吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let params = {
            id: this.currentContract,
            user: this.user_realname,
            operatetime: utils.getDateTime()
          }

          this.$axios.put('/financialConfirm/', params).then(res => {
            console.log(res.data)
            this.$message({
              type: 'success',
              message: '财务确认成功!',
              duration: 2000
            })
            this.getContractDetail()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消财务确认',
            duration: 2000
          })
        })
    },

    /*
    结单
    */
    Statement() {
      this.$confirm('确认结单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let params = {
            id: this.currentContract,
            user: this.user_realname,
            operatetime: utils.getDateTime()
          }

          this.$axios.put('/statement/', params).then(res => {
            console.log(res.data)
            this.$message({
              type: 'success',
              message: '结单成功!'
            })
            this.getContractDetail()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消结单'
          })
        })
    },
    //返回合同列表页
    backList() {
      this.$router.push({ name: 'contract_manage', query: { from: 'detail' } })
    },
    //el-table样式列表
    TableHeaderRowStyle() {
      return 'height:35px'
    },
    TableHeaderCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;font-weight:700;color:#000;text-align:center;'
    },
    TableRowStyle() {
      return 'height:35px'
    },
    TableCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;color:#000;text-align:center;'
    },
    //设置物资调配排序方式
    setWuzidiaopei_order(value) {
      this.wuzidiaopei_order = value
      console.log('this.wuzidiaopei_order:', this.wuzidiaopei_order)
      this.getWuzidiaopei()
    },
    //设置库房出库排序方式
    setKufangchuku_order(value) {
      this.kufangchuku_order = value
      console.log('this.kufangchuku_order:', this.kufangchuku_order)
      this.getKufangchuku()
    },
    //设置库房回库排序方式
    setKufanghuiku_order(value) {
      this.kufanghuiku_order = value
      console.log('this.kufanghuiku_order:', this.kufanghuiku_order)
      this.getKufanghuiku()
    }
  },
  created() {
    this.getContractDetail()
    this.getContractItem()
    this.getContractItemRemark()
    this.getAdditionalContract()
    this.getAddiContractRemark()
    this.getCollection()
    this.getCollectionRemark()
    this.getfCollection()
    this.getFcollectionRemark()
    this.getWuzidiaopei()
    this.getWuzidiaopeiRemark()
    this.getKufangchuku()
    this.getKufangchukuRemark()
    // this.getWuzihuikudiaopei()
    this.getKufanghuiku()
    this.getKufanghuikuRemark()
    this.getJiesuan()
    this.getJiesuanRemark()
  }
}
</script>
<style scoped>
#container {
  width: 100%;
  /* padding: 10px; */
  background-color: rgb(238, 240, 245);
}
.title1 {
  height: 30px;
  width: 100px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 5px;
  margin-top: 5px;
  background-color: rgb(37, 164, 168);
  font-size: 15px;
  color: white;
}
.title2 {
  height: 30px;
  width: 100px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 5px;
  margin-top: 5px;
  background-color: rgb(33, 124, 86);
  font-size: 15px;
  color: white;
}
.title3 {
  height: 30px;
  width: 100px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 5px;
  margin-top: 5px;
  background-color: rgb(37, 127, 211);
  font-size: 15px;
  color: white;
}
.title4 {
  height: 30px;
  width: 100px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 5px;
  margin-top: 5px;
  background-color: rgb(33, 124, 86);
  font-size: 15px;
  color: white;
}
.radio_order {
  float: left;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: 20px;
  font-size: 15px;
  color: white;
}
.contractItemHeader {
  display: flex;
  justify-content: space-between;
}
.demo-image__lazy {
  height: 600px;
  overflow-y: auto;
}
#main {
  width: 100%;
  /* padding: 10px; */
  /* background-color: rgb(238, 240, 245); */
}
#contractContainer {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  background-color: rgb(255, 255, 255);
}
#collectionContainer {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  background-color: rgb(255, 255, 255);
}
#chukuhuikuContainer {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  background-color: rgb(255, 255, 255);
}
#contractDetail {
  width: 100%;
  background-color: rgb(142, 192, 201);
  margin-bottom: 20px;
}
#contractItem,
#additionalContract {
  width: 100%;
  background-color: rgb(238, 240, 245);
  margin-bottom: 20px;
}
#collection {
  width: 100%;
  background-color: rgb(142, 207, 186);
  margin-bottom: 20px;
}
#fcollection {
  width: 100%;
  background-color: rgb(238, 240, 245);
  margin-bottom: 20px;
}
#wuzidiaopei {
  width: 100%;
  background-color: rgb(120, 188, 233);
  margin-bottom: 20px;
}
#kufanghuiku {
  width: 100%;
  background-color: rgb(238, 240, 245);
  margin-bottom: 20px;
}
.remark_title {
  padding: 10px;
  color: blue;
  font-size: 14px;
}
</style>
<style>
.el-table .cell {
  white-space: pre-line;
  text-align: center;
}
</style>
